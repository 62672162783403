<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Question</v-card-title>
        <v-card-text>
          <template>
            <FormTextArea
              :value="form['translatableText']"
              label="Translatable Text"
              :rules="summaryRules"
              @input="form['translatableText'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['key']"
              label="Key"
              :rules="keyRules"
              @input="form['key'] = $event"
            />
          </template>

          <template>
            <FormImage
              :value="form['imageEN']"
              label="Image EN"
              @input="form['imageEN'] = $event"
            />
          </template>
          <template>
            <FormImage
              :value="form['imageMS']"
              label="Image MS"
              @input="form['imageMS'] = $event"
            />
          </template>
          <template>
            <FormImage
              :value="form['imageZH']"
              label="Image ZH"
              @input="form['imageZH'] = $event"
            />
          </template>
          <template>
            <FormAudio
              :value="form['audioEN']"
              label="Audio EN"
              @input="form['audioEN'] = $event"
            />
          </template>
          <template>
            <FormAudio
              :value="form['audioMS']"
              label="Audio MS"
              @input="form['audioMS'] = $event"
            />
          </template>
          <template>
            <FormAudio
              :value="form['audioZH']"
              label="Audio ZH"
              @input="form['audioZH'] = $event"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isPublished']"
              label="Published"
              @input="form['isPublished'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Question/FormInput";
import FormImage from "@/components/Moderator/Question/FormImage";
import FormTextArea from "@/components/Moderator/Question/FormTextArea";
import FormAudio from "@/components/Moderator/Question/FormAudio";
import FormRadio from "@/components/Moderator/Question/FormRadio";

import axios from "axios";
import store from "../../../store";
import { mapState } from "vuex";
export default {
  props: ["data", "url", "callbackSuccess"],
  components: {
    FormInput,
    FormImage,
    FormTextArea,
    FormAudio,
    FormRadio
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC

    //BOC:[form]
    form: {
      translatableText:
        '{"en":"Test Title","ms":"Tajuk Ujian","zh":"测试标题"}',
      key: null,
      imageEN: null,
      imageMS: null,
      imageZH: null,
      audioEN: null,
      audioMS: null,
      audioZH: null,
      isPublished: false
    },
    fields: [],
    rules: {},
    keyRules: [],
    nameRules: [],
    summaryRules: [],
    mobile: null,
    code: null,
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      this.keyRules = [(v) => !!v || "Page key is required"];
      this.summaryRules = [(v) => !!v || "Question text is required"];

      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    async submit() {
      // this.api.params = this.$_.clone(this.form);
      // this.$api.fetch(this.api);
      this.api.isLoading = true;
      const formData = new FormData();
      const token = "Bearer " + store.state.auth.data.Session.token;
      const config = {
        headers: { Authorization: token },
      };

      formData.append("translatableText", this.form.translatableText);
      formData.append("key", this.form.key);
      formData.append("imageEN", this.form.imageEN);
      formData.append("imageMS", this.form.imageMS);
      formData.append("imageZH", this.form.imageZH);
      formData.append("audioEN", this.form.audioEN);
      formData.append("audioMS", this.form.audioMS);
      formData.append("audioZH", this.form.audioZH);
      formData.append("isPublished", this.form.isPublished);
      try {
        // Make the API request
        const response = await axios.post(this.url, formData, config);

        this.api.isLoading = false;
        this.callbackSuccess(response.data);
      } catch (error) {
        this.api.isLoading = false;
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = error;
      }
    },
    //EOC
  },
};
</script>

<style>
</style>